<!-- Top navigation -->
<div *ngIf="config?.showHeader">
  <app-module-loader [moduleId]="'common-header'" [selector]="'app-common-header'" [load]="config?.showHeader" [inputs]="{config: config}"></app-module-loader>
</div>

<div *ngIf="config?.showBasicHeader">
  <app-basic-header [config]="config" />
</div>

<!-- Main view/routes wrapper-->
<router-outlet></router-outlet>

