<div class="head-space-fill"></div>

<div class="header-wrap">
  <div class="inner-wrap">
    <div class="left">
      <div class="logo">
        <a [routerLink]="'/'" [ngClass]="{'no-event': !config?.logoNavigate}">
          <img loading="lazy" [attr.data-src]="util?.logoUrlDark" [alt]="util?.domain_details?.domain_name" [default]="util.defaultImg">
        </a>
        <!-- only shown on mobile -->
        <span *ngIf="config?.showMenus" class="hamburger-icon" (click)="toggleMenu()">
          <mat-icon>{{ismenuopen ? 'close': 'menu'}}</mat-icon>
        </span>
      </div>
    </div>
    <div class="right" #rightRef>
      <div *ngIf="config?.showMenus" class="links">
        <a *ngFor="let item of navOptions" [routerLink]="item.link" class="option" mat-ripple>{{item.text}}</a>
        <a class="donate-btn" mat-ripple [routerLink]="'new/payment'" [queryParams]="{ct: donateBtnParams.ct, id: donateBtnParams.id, payment: 'form'}">Donate Now</a>
      </div>
      <div *ngIf="config?.showSecure" class="secure-tag">
        <img src="/assets/images/story/icon-secure-lock.png" alt="secure lock" />
        <div class="secure-txt">100% Secure</div>
      </div>
    </div>
  </div>
</div>