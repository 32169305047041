import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { ModuleLoaderModule } from '../common/module-loader/module-loader.module';
import { BasicHeaderComponent } from '../core/basic-header/basic-header.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ModuleLoaderModule,
    BasicHeaderComponent
  ],
  declarations: [MainLayoutComponent],
  exports: [MainLayoutComponent],
})
export class LayoutsModule { }
