import { Injectable, Inject, OnInit } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { UtilService } from '../util/util.service';
import { VariablesService } from '../variables/variables.service';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { IFundraiser, IFundraiserMetaData } from '../../models/fundraiser.model';

@Injectable({
  providedIn: 'root'
})
export class SeoService implements OnInit {

  public schema = {
    'home_org': {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      'name': `${environment.APP.KETTO_DOMAIN_URL}`,
      'description': 'Online Crowdfunding Platform and Website in India for fundraising of Social, Charity, Movies, Music, Personal and Creative causes. Visit us online!',
      'url': `${environment.APP.KETTO_DOMAIN_URL}`,
      'logo': `${environment.APP.IMAGE_DOMAIN}/images/logo-light.jpg`,
      'image': `${environment.APP.IMAGE_DOMAIN}/images/logo-light-bg.svg?w=80&dpr=1.0`,
      'potentialAction': {
        '@type': 'SearchAction',
        // tslint:disable-next-line:max-line-length
        'target': `${environment.APP.KETTO_DOMAIN_URL}/campaign/campaign_listing.php?utf8=%E2%9C%93&search-input={search_term_string}&search={search_term_string}`,
        'query-input': 'required name=search_term_string'
      },
      'contactPoint': [
        {
          '@type': 'ContactPoint',
          'telephone': '(+91) ',
          'contactType': 'customer service',
          'areaServed': ['India'],
          'availableLanguage': ['English']
        }
      ],
      'sameAs': [
        'https://en.wikipedia.org/wiki/Ketto',
        'https://www.youtube.com/channel/UC8-61bHuHLW1vWneDhVkZ9g',
        'https://in.linkedin.com/company/ketto-online-ventures-private-limited',
        'https://www.facebook.com/ketto.org/',
        'https://twitter.com/ketto'
      ],
      'address': {
        '@type': 'PostalAddress',
        'streetAddress': '1302-1306, 13th Floor, A-4, Peninsula Park, Fun Republic lane, Behind Hard Rock Cafe, Andheri (West)',
        'addressRegion': 'Mumbai',
        'postalCode': '400 053',
        'addressCountry': 'India'
      }
    },
    'home_webpage': {
      '@context': 'http://schema.org',
      '@id': `${environment.APP.KETTO_DOMAIN_URL}`,
      '@type': 'WebPage',
      'url': `${environment.APP.KETTO_DOMAIN_URL}`,
      'name': `${environment.APP.KETTO_DOMAIN_URL}`
    }
  };

  constructor(
    public meta: Meta,
    public utilService: UtilService,
    public variablesService: VariablesService,
    @Inject(DOCUMENT) public document: any
  ) { }

  ngOnInit(): void {
    this.schema.home_org.contactPoint[0].telephone += this.variablesService.contactUsMobNumber;
  }

  /**
   * Add the meta tags into the dom
   * @param data definations
   */
  addMetaTags(data: MetaDefinition[]) {
    for (const item of data) {
      if (item.name) {
        this.meta.updateTag(item);
      }
      if (item.property) {
        this.meta.updateTag(item);
      }
    }
  }
  /**
   * Remove the meta tags froj the dom
   * @param data definations
   */
  removeMetaTags(data: MetaDefinition[]) {
    if (data && data.length) {
      for (const item of data) {
        if (item.name) {
          this.meta.removeTag(`name='${item.name}'`);
        }
        if (item.property) {
          this.meta.removeTag(`property='${item.property}'`);
        }
      }
    }
  }
  /**
   * Add the meta sigle tag into the dom
   * @param data definations
   */
  addMetaTag(data: MetaDefinition) {
    this.meta.addTag(data);
  }
  /**Create tag object
   * @param data Object
  */
  createTagObject(data): MetaDefinition[] {
    const tags = [
      { name: 'author', content: data.site },
      { name: 'description', content: data.description },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '' },
      { name: 'twitter:image', content: data.image },
      { name: 'twitter:description', content: data.description },
      { property: 'fb:app_id', content: environment.facebook_id },
      { property: 'og:title', content: data.title },
      { property: 'og:site_name', content: data.site },
      { property: 'og:type', content: data.site },
      { property: 'og:url', content: data.url },
      { property: 'og:image', content: data.image },
      { property: 'og:image:secure_url', content: data.image },
      { property: 'og:description', content: data.description },
    ];

    if (data?.robots) {
      tags.push({ name: 'robots', content: data?.robots });
    }

    if (data.campaigner && !data.campaigner.includes('Dream11')) {
      tags.push({
        name: 'keywords',
        content: `${data.title.trim()}, ${data.campaigner.trim()}, crowdfunding platform in India, raise funds, ${data.keywords}`
      });
      tags.push({
        name: 'twitter:title',
        content: `${data.title.trim()} by ${data.campaigner.trim()}`
      });
    } else {
      tags.push({
        name: 'keywords',
        content: `${data.title.trim()}, crowdfunding platform in India, raise funds, ${data.keywords}`
      });
      tags.push({
        name: 'twitter:title',
        content: `${data.title.trim()}`
      });
    }
    return tags;
  }
  /**
   * Get Meta data of the fundraiser
   * @param fundraiser FUndraiser object
   * @param campaignUrl Campain URL of fundraiser
   */
  fundraiserMetaTags(fundraiser: IFundraiser, campaignUrl): IFundraiserMetaData {
    const campaignerName = fundraiser.campaigner ? fundraiser.campaigner.full_name : '';
    return {
      description: this.utilService.capitalizeFirstLatter(campaignerName)
        + ` wants to raise funds for ${fundraiser.title}`
        + `. Your donation has the power to help them move closer to their goal amount. Please contribute.`,
      keywords: 'fundraiser',
      campaigner: campaignerName,
      title: fundraiser.title,
      image: fundraiser.gallery && fundraiser.gallery.length ? fundraiser.gallery[0].cdn_path : '',
      url: `${environment.APP.KETTO_DOMAIN_URL}/fundraiser/${fundraiser.custom_tag}`,
      site: ''
    };
  }

  homePageMetaTags(): MetaDefinition[] {
    return [
      { name: 'author', content: `` },
      // tslint:disable-next-line:max-line-length
      { name: 'description', content: `Start a fundraiser on India's #1 Crowdfunding website, for Medical, Education, Sports, NGO and Child Welfare causes and raise funds for FREE.` },
      { name: 'keywords', content: `crowdfunding india, crowdfunding, crowdfunding website, crowdsourcing` },
      { property: 'og:site_name', content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'google-site-verification', content: '7VFFpLkP2-6IuRFYDLGDJmaOYVR-4cElDEKkSY6XRKY' },
    ];
  }
  howitworksPageMetaTags() {
    return {
      // tslint:disable-next-line:max-line-length
      description: `What is crowdfunding? & how crowdfunding works? get answers to all your queries regarding the concept of crowdfunding. Visit today!`,
      keywords: 'what is crowdfunding?, how crowdfunding works?',
      title: 'What is Crowdfunding? | How Crowdfunding Works?',
      image: '//img.youtube.com/vi/4uu-mKoFR6w/0.jpg',
      url: '',
      site: ''
    };
  }

  crowdfundingFundraiserMetaTags() {
    return {
      description: 'one of the most trusted fundraising portals in India! We are helping many people to raise funds for their loved ones for medical, creative, animal and several other causes. Visit us to browse and start fundraiser!',
      keywords: 'fundraising, fundraising india, online furndraising, fundraising in india',
      title: 'Fundraising – Best Online Fundraising Site in India',
      image: '//img.youtube.com/vi/4uu-mKoFR6w/0.jpg',
      url: '',
      site: ''
    };
  }

  stroyPageMetaTags(fundraiser: IFundraiser) {
    const storyDesc = this.document.createElement('div');
    const htmlString = fundraiser.story_description ? fundraiser.story_description.info_1 : '';
    storyDesc.innerHTML = htmlString.replace(/<img[^>]*>/g, '');
    let content: string = storyDesc.textContent.replace(/(\r\n|\n|\r)/gm, '');
    content = content.length > 155 ? content.substring(0, 155).concat('...') : content;
    const campaignerName = fundraiser?.campaigner?.full_name || '';
    return {
      description: `${this.utilService.capitalizeFirstLatter(campaignerName)}, ${content}`,
      keywords: 'story',
      campaigner: campaignerName,
      title: fundraiser?.story_title?.info_1 || fundraiser?.title || '',
      image: fundraiser?.leaderboard?.cdn_path || fundraiser?.theater?.cdn_path || '',
      url: `${this.utilService.domain_details?.domain_url}/stories/${fundraiser.custom_tag}`,
      site: ''
    };
  }

  /**Schema.Org object  */
  schemaOrgObject(seoJson: any, id?) {
    if(!seoJson) { return; }
    const s = this.document.createElement('script');
    s.type = 'application/ld+json';
    s.innerHTML = JSON.stringify(seoJson);
    if (id) { s.id = id; }
    const head = this.document.getElementsByTagName('head')[0];
    head.appendChild(s);
  }

  schemaBodyObject(seoJson: any, id?) {
    const s = this.document.createElement('script');
    s.type = 'application/ld+json';
    s.innerHTML = JSON.stringify(seoJson);
    if (id) { s.id = id; }
    const body = this.document.getElementsByTagName('body')[0];
    body.appendChild(s);
  }

  createCanonicalURL() {
    let url = this.document.URL;
    if (!this.utilService.isBrowser) {
      url = environment.APP.KETTO_DOMAIN_URL + url;
    }
    if (url.match('new/crowdfunding')) {
      url = environment.APP.KETTO_DOMAIN_URL;
    }
    const existingRel: any = this.document.querySelector('link[rel="canonical"]');
    if (existingRel) {
      existingRel.href = url;
    } else {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
      link.setAttribute('href', url);
    }
  }

  updateCanonicalURL(url: string) {
    const existingRel: any = this.document.querySelector('link[rel="canonical"]');
    if (existingRel) {
      existingRel.href = environment.APP.KETTO_DOMAIN_URL + url;
    }
  }

  createAmpHtml(page: string, customTag: string) {
    const url = environment.APP.KETTO_DOMAIN_URL + `/amp/${page}/${encodeURIComponent(customTag)}`;
    const existingRel: any = this.document.querySelector('link[rel="amphtml"]');
    if (existingRel) {
      existingRel.href = url;
    } else {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'amphtml');
      this.document.head.appendChild(link);
      link.setAttribute('href', url);
    }
  }

  removeAmpHtml() {
    const existingAmpHtml = this.document.querySelector('link[rel="amphtml"]');
    if (existingAmpHtml) {
      existingAmpHtml.remove();
    }
  }

  /**Schema object for videos */
  schamObjectForVideos(data) {
    const videoObject = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      'name': data.title,
      'description': data.desc,
      'thumbnailUrl': `https://img.youtube.com/vi/${data.videoId}/0.jpg`,
      'uploadDate': `${data.startDate}`,
      'contentUrl': `https://www.youtube.com/embed/${data.videoId}`
    };
    this.schemaOrgObject(videoObject);
  }
}
