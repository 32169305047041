import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ErrorHandlerModule } from './error-handler/error-handler.module';
import { LayoutsModule } from './layouts/layouts.module';
import { SharedModule } from './shared/shared.module';
// import { GoogleOnetapLoginModule } from './common/google-onetap-login/google-onetap-login.module';



@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    NgtUniversalModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ErrorHandlerModule,
    SharedModule.forRoot(),
    LayoutsModule,
    // GoogleOnetapLoginModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
