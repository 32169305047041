import { ButtonModule } from './../common/button/button.module';
/**Modules */
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { InViewportModule } from 'ng-in-viewport';
import { FacebookModule } from 'ngx-facebook';

import { ModuleLoaderModule } from './../common/module-loader/module-loader.module';

/**Components */
import { PaymentRoutingComponent } from './components/payment-routing/payment-routing.component';
import { CurrencyIconComponent } from './components/currency-icon/currency-icon.component';
import { LogoutComponent } from './components/logout/logout.component';
import { LoginViaTokenComponent } from './components/login-via-token/login-via-token.component';
import { ImgLoaderDirective } from './directives/img-loader.directive';

/**Gaurds */
import { AuthGuard } from './guards/auth/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { SipGuard } from './guards/sip/sip.guard';

/**Services */
import { HomepageService } from './../homepage/services/homepage.service';
import {
  AuthService, CampaignService, HttpInterService, ScriptLoaderService,
  StorageService, UtilService, FundraiserService, SeoService, EventsService,
  FbService, ShareMessagesService, VariablesService, DonationService,
  PaymentService, PusherService, CookieService, TickrService,
  PortfolioService, SignupService, GalleryService
} from './services';
const Services = [
  AuthService, CampaignService, HttpInterService, ScriptLoaderService,
  StorageService, UtilService, FundraiserService, SeoService, EventsService,
  FbService, ShareMessagesService, VariablesService, DonationService,
  PaymentService, PusherService, CookieService, HomepageService, TickrService,
  PortfolioService, SignupService, GalleryService, AuthGuard, LoginGuard, SipGuard
];
// Pipes
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { CustomTranslatePipe } from './pipes/translate/translate.pipe';
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';
import { InrFormatPipe } from './pipes/inr-format/inr-format.pipe';
import { UtcConvertPipe } from './pipes/utc-convert/utc-convert.pipe';
import { NumberAbbreviationPipe } from './pipes/number-abbreviation/number-abbreviation.pipe';
import { DayjsPipe } from './pipes/dayjs/dayjs.pipe';
import { DiffTimePipe } from './pipes/difftime/difftime.pipe';
import { TextEllipsisPipe } from './pipes/text-ellipsis/text-ellipsis.pipe';
import { TypeofPipe } from './pipes/typeof/typeof.pipe';
import { DomainNamePipe } from './pipes/domain-name/domain-name.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RecaptchaModule,
    InViewportModule,
    FacebookModule.forRoot(),
    ModuleLoaderModule,
    ButtonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatBottomSheetModule
  ],
  declarations: [
    SafeHtmlPipe,
    CustomTranslatePipe,
    SafeUrlPipe,
    InrFormatPipe,
    UtcConvertPipe,
    DayjsPipe,
    DiffTimePipe,
    NumberAbbreviationPipe,
    PaymentRoutingComponent,
    CurrencyIconComponent,
    LogoutComponent,
    ImgLoaderDirective,
    LoginViaTokenComponent,
    TextEllipsisPipe,
    TypeofPipe,
    DomainNamePipe
  ],
  exports: [
    RecaptchaModule,
    InViewportModule,
    SafeHtmlPipe,
    CustomTranslatePipe,
    SafeUrlPipe,
    InrFormatPipe,
    UtcConvertPipe,
    NumberAbbreviationPipe,
    DayjsPipe,
    DiffTimePipe,
    TextEllipsisPipe,
    PaymentRoutingComponent,
    CurrencyIconComponent,
    ImgLoaderDirective,
    TypeofPipe,
    DomainNamePipe
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        // Services
        ...Services,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterService,
          multi: true
        }
      ]
    };
  }
}
