import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { IDonor } from '../../../common/payment/models/order.model';
import { ICLientData } from '../../models/client.model';
import { IMainLayoutConfig } from '../../models/main-layout.config';
import { IUser } from '../../models/user.model';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { IBasicInfo, IFundraiser, ISipCause } from '../../models/fundraiser.model';
import { STORY_LANGUAGE } from '../../models/i18n/stories/en';
import { PaymentService } from '../payment/payment.service';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  isVariableLogin = false;
  varLoginData: { user: any, userdata: any } = { user: null, userdata: null };
  isFundraiserLeftDialog = false;
  email_Pattern_Regex = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/

  /**User Information */
  userData = new BehaviorSubject<IUser>(null);
  /**Client machine location info */
  clientLocationData = new BehaviorSubject<ICLientData>(null);

  // Theme of the website
  theme = new Subject<any>();

  /**Fundraier Object */
  fundraiser: IFundraiser;
  /**Fundraier Object Basic info*/
  basicInfo = {} as IBasicInfo;

  /**Type of the campaign like story or fundraiser */
  campaignType = '';

  showSocialShare = true;

  /**Header and footer config in main layout */
  mainLayoutConfig = new BehaviorSubject<IMainLayoutConfig>({
    animateHeader: false,
    logoNavigate: true,
    showHeader: true,
    showMenus: true,
    showProfile: true,
    showSearch: true,
    showSecure: false,
    trustedLine: false
  });

  isMenuOpen = new BehaviorSubject(false);

  /**Scroll into view varaible */
  scrollIntoView = new Subject<{ name: string, scroll: boolean }>();

  /**Beneficiary object */
  beneficiary;
  /**The payment option set */
  setPaymentOption = new BehaviorSubject<{
    card?: boolean, netbanking?: boolean, upi?: boolean, paytm?: boolean
  }>({});
  /**Show thank you banner */
  showThankYoubanner = new BehaviorSubject<any>(null);
  /**Meta data of the page */
  metaDataOfPage;
  /**The order id of currently activated/created order */
  currentOrderId;
  /**Name of the current page */
  pageName: string;
  /**Type of the payment dialoge */
  paymentDialogType = new BehaviorSubject<string>('');
  // lead form hidden field config
  leadMailTo = 'leads.ketto@gmail.com';
  leadMailToInfo = 'info@ketto.org';
  leadSubject = 'You received a new lead';
  leadType = 'P - Talk to us (browse fundraisers)';
  // Social links and button
  social = true;
  // Payment options params
  paymentOption: boolean;
  // Check if phone pe swith
  isPhonePe: boolean;
  // check if opened in third party apps like paytm, phonepe
  thirdPartyApp: boolean;
  // Check if phone pe swith
  isPaytmMini: boolean;

  /** */
  defaultIPLocation = {
    'country_code': 'IN',
    'country_name': 'India',
    'city_name': 'Mumbai',
    'region_name': 'Maharashtra',
    'latitude': 19.0144100189209,
    'longitude': 72.84793853759766,
    'zip_code': '400099'
  };
  /**User last four digits of the card */
  lastOrderInfo = new BehaviorSubject<any>(null);
  /**Last payment mode */
  lastOrderMode = new BehaviorSubject<{ mode: string, value: string, gateway: string }>({ mode: '', value: '', gateway: '' });
  /**Previos visited page url */
  previousPageURL = '';
  qrOpened = false;

  globalVar = new ReplaySubject<any>(1);
  selectTipPercentage;

  /**Unique Id for truecaller verification */
  req_nonce = '';

  /** thank you page url params */
  tyParams;
  createCart;
  createCartNewUser;

  abandonedCart = new BehaviorSubject<any>(null);

  /** Translate Obverable */
  translateLangArray = new BehaviorSubject<any>([]);
  tranlatedLanguage = 'en';
  languageObs = new BehaviorSubject<string>('en');
  campaignId;
  isOrderLoading = false;
  showTimer = false;
  disableUpi = false;
  gtmPageData;

  // leads mobile number
  contactUsMobNumber = '9930088522';

  paymentOrigin;
  isFundraiserEnded;
  stripeUsToken;

  /**check for memorial sip */
  sip_cause = new BehaviorSubject<ISipCause>(null);

  criteoPartnerIDs = {
    'IN': 56509,
    'AE': 69550
  };

  currencyObj;
  onUpiOptionClick = new Subject<{
    panelClass: string,
    name: string,
    route: string,
  }>();

  upiTimer = false;
  upiTimerOuter = new Subject<boolean>();

  deviceType;
  clickedAnywhere = false;

  loginMethod: string = '';
  loginType: string = '';

  upi_payment_status = new BehaviorSubject<'success' | 'failed' | 'canceled' | ''>('');

  event_info = { info_1: '', info_2: '', info_3: '', info_4: '' };

  currency;

  non_login_user_data: any;
  domainTheme = new Subject<any>();
  domainName = '';
  domainLogo = '';
  domainFavicon = '';
  allDomains = {
    'default': { url: 'support.childhelpfoundation.in', name: 'Child Help Foundation', logo: 'https://d1vdjc70h9nzd9.cloudfront.net/media/domainconfig/test.childhelpfoundation.in/image/MEOzfX7GzoX7k0wsQMbqn9UJF736XTFpFedlREGB.png', favicon: 'https://d1vdjc70h9nzd9.cloudfront.net/media/domainconfig/test.childhelpfoundation.in/image/7QBnos38mziT4jd2MGbl1D5eeitsgxs6bUWGZfOi.ico' },
    'support.childhelpfoundation.in': { url: 'support.childhelpfoundation.in', name: 'Child Help Foundation', logo: 'https://d1vdjc70h9nzd9.cloudfront.net/media/domainconfig/test.childhelpfoundation.in/image/MEOzfX7GzoX7k0wsQMbqn9UJF736XTFpFedlREGB.png', favicon: 'https://d1vdjc70h9nzd9.cloudfront.net/media/domainconfig/test.childhelpfoundation.in/image/7QBnos38mziT4jd2MGbl1D5eeitsgxs6bUWGZfOi.ico' }
  };
  campaignCount;
  leadQueryType = [
    { value: 'want_to_raise_funds', text: 'Want to raise funds' },
    { value: 'transaction_related_query', text: 'Transaction related query' },
    { value: 'other_query', text: 'Other query' }
  ];
  frCauses = [
    {
      label: 'Medical Treatment',
      value: 'medical'
    },
    {
      label: 'NGO / Charity',
      value: 'ngo'
    },
    {
      label: 'Other Cause',
      value: 'other'
    },
  ];
  hospitalStatus = [
    { value: 'currently_hospitalised', text: 'Currently hospitalised' },
    { value: 'hospitalised_in_6months', text: 'Will be hospitalised within 6 months' },
    { value: 'discharged_from_hospital', text: 'Discharged from hospital' },
    { value: 'does_not_require_hospitalisation', text: 'Does not require hospitalisation' }
  ];
  fundsUsed = [
    { value: 'cancer_treatment', text: 'Cancer treatment' },
    { value: 'accident_treatment', text: 'Accident treatment' },
    { value: 'liver_kidney_transplant', text: 'Liver/Kidney transplant' },
    { value: 'heart_operation', text: 'Heart operation' },
    { value: 'premature_baby', text: 'Pre-mature baby care (NICU)' },
    { value: 'medicines_treatment', text: 'Medicines (Ongoing treatment)' },
    { value: 'memorial', text: 'Memorial (Patient expired)' },
    { value: 'others', text: 'Others' }
  ];
  RAISED = '1100 Crs+';
  DONORS = '72 Lakh+';
  FUNDRAISERS = '3.2 Lakh+';
  PLATFORMFEE = '0%';
  FOLLOWERS = '1.5M +';
  fundraiserCount = '1,50,256';
  isFOS;
  startAFrUrls = {
    'medical': 'new/crowdfunding/medical',
    'non-medical': 'new/crowdfunding/non-medical'
  };
  adminToken = '';
  parentCauses = [
    { label: 'Myself', id: 20, value: 'myself', cause: 'Personal' },
    { label: 'Family Member', id: 20, value: 'family', cause: 'Personal' },
    { label: 'Friend', id: 20, value: 'friend', cause: 'Personal' },
    { label: 'Pet or Animal', id: 20, value: 'pet', cause: 'Personal' },
    { label: 'Charity or Non-profit', id: 48, value: 'ngo', cause: 'Ngo' },
    { label: 'Creative', id: 49, value: 'creative', cause: 'Creative' },
  ];
  startFundraiserWidgetStatus = new BehaviorSubject(false);
  draftData = new BehaviorSubject<any>(null);
  abondenedCart = new BehaviorSubject<any>(null);

  frCausesVernacular = [
    {
      label: {
        English: 'Medical Treatment',
        Hindi: 'चिकित्सा उपचार',
        Telugu: ' వైద్య చికిత్స',
        Tamil: 'மருத்துவ சிகிச்சை',


      },
      value: 'medical'
    },
    {
      label: {
        English: 'NGO / Charity',
        Hindi: 'एन.जी.ओ / चैरिटी',
        Telugu: 'ఎన్జీఓ / దాతృత్వం',
        Tamil: 'என்ஜிஓ / தொண்டு',


      },
      value: 'ngo'
    },
    {
      label: {
        English: 'Other Cause',
        Hindi: 'अन्य कारण',
        Telugu: 'ఇతర కారణాలు',
        Tamil: 'பிற காரணங்கள்',

      },
      value: 'other'
    },
  ];
  brandSurveyData = [
    { 'value': 'Search Engine (Google, Yahoo, etc)', 'label': 'Search Engine (Google, Yahoo, etc)' },
    { 'value': 'Facebook, Instagram Ad / Post', 'label': 'Facebook, Instagram Ad / Post' },
    { 'value': 'Twitter Ad / Post', 'label': 'Twitter Ad / Post' },
    { 'value': 'YouTube Ad / Post', 'label': 'YouTube Ad / Post' },
    { 'value': 'Brochure / Banner in Hospital', 'label': 'Brochure / Banner in Hospital' },
    { 'value': 'Recommended by Doctor', 'label': 'Recommended by Doctor' },
    { 'value': 'Recommended by Hospital Staff Member', 'label': 'Recommended by Hospital Staff Member' },
    { 'value': 'Recommended By Friend / Family member', 'label': 'Recommended By Friend / Family member' },
    { 'value': 'Ketto Representative', 'label': 'Ketto Representative' },
    { 'value': 'Recommended by NGO', 'label': 'Recommended by NGO' },
    { 'value': 'WhatsApp DM / Group', 'label': 'WhatsApp DM / Group' },
    { 'value': 'Influencer / Content Creator', 'label': 'Influencer / Content Creator' },
    { 'value': 'Newspaper / TV / Billboard', 'label': 'Newspaper / TV / Billboard' }
  ];
  salaryRangeData = [
    { label: '0-5 lakh', value: '0-5 lakh' },
    { label: '5-10 lakh', value: '5-10 lakh' },
    { label: '10-15 lakh', value: '10-15 lakh' },
    { label: '15-20 lakh', value: '15-20 lakh' },
    { label: '20+ lakh', value: '20+ lakh' },
    { label: 'None', value: 'None' },
  ];
  genderData = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
    { label: 'Other', value: 'NA' },
  ];
  selectedHospital: any;
  purposeSelected = false;
  /**Call comment card function */
  commentCard = new Subject<any>();

  /**Filter Data for Browse Fundraiser */
  appliedFilters = new BehaviorSubject<any>({ 'category': 'C1', 'type': 'T1', 'location': 'L1' });
  /**Variable to toggle reset on Browse */
  resetFilter = new Subject<any>();
  /**Apply Mobile Filter */
  applyMobileFilter = new Subject<any>();
  /**Counter check to fire b-lazy */
  resetCounter = new BehaviorSubject<boolean>(false);
  /**Counter check to fire b-lazy */
  showApply = new BehaviorSubject<boolean>(false);

  // healthfirst dashboard data
  hfDashboardData = null;

  /**header logo click event */
  logoOnclik = new Subject<any>();
  /**Open search box */
  openHeaderSearch = new Subject<boolean>();
  /**Comment tab swith */
  commentTabSwitch = new Subject<string>();
  /**Thankyou banner */
  donationDetails = new BehaviorSubject<IDonor>(null);
  showCouponOffer = true;
  isCategoryMenuCliked: any;

  public otdpayment = new BehaviorSubject<any>(null);

  /**Custom insurance members */
  members = {
    self: { checked: true, count: 1, showCount: false, data: {} },
    spouse: { checked: false, count: 0, showCount: false, data: {} },
    son: { checked: false, count: 0, showCount: true, maxAllowed: 2, data: {} },
    daughter: { checked: false, count: 0, showCount: true, maxAllowed: 2, data: {} }
  };
  /**Covid premiums */
  covidPremiums = {
    '3 Lac': 1563,
    '1.5 Lac': 785,
    '50,000': 443,
  };
  portfolioCounts = new BehaviorSubject('');
  impactData: any;
  subsCount = new BehaviorSubject<any>({});
  sipActiveOnPayment = new Subject<string>();
  isQuizCompleted = false;
  lastQuizResponse = false;
  typSipAmount: any;
  typSipAmountDefaultINR = 100;
  offerActivated = new ReplaySubject<number>(1);
  validCausePM = [4, 7, 59, 103, 150, 156, 159];
  campaignIdForSip: any;
  trucallerRes = new Subject<{ name: string, phone: string, email: string }>();
  previousUrl: any;
  showDock = new Subject<boolean>();
  appLink = {
    android: 'https://play.google.com/store/apps/details?id=org.ketto.sip',
    android_mcd: 'https://play.google.com/store/apps/details?id=org.ketto.mcd',
    iOS: 'https://apps.apple.com/sr/app/ketto-sip/id1582755806',
    iOS_mcd: 'https://apps.apple.com/sr/app/ketto-for-fundraiser/id1602237545',
    app_flyer: 'https://kettosip.onelink.me/95wu/q1hg3zku',
    app_flyer_android: 'https://kettosip.onelink.me/95wu/jgrg5im3'
  };
  min_sip_amounts = { INR: 100, USD: 10, GBP: 10, EUR: 10, AED: 20, SGD: 30, SAR: 50 };
  system_event = {
    contribute_initiated: { info_1: '', info_2: '', info_3: '', info_4: '' },
    contribute_cart: { info_1: '', info_2: '', info_3: '', info_4: '' },
    order_created: { info_1: '', info_2: '', info_3: '', info_4: '' }
  }
  campaignPurpose: any;
  insurance_vendors = {
    bima: { // vendor
      yearly: { // frequency
        care: { INR: 799 }, plus: { INR: 1599 } // plans
      },
      monthly: {
        care: { INR: 99 }, plus: { INR: 199 } // plans
      },
      medibuddy: { // vendor
        yearly: { // frequency
          essentials: { INR: 1998 }, plus: { INR: 3999 } // plans
        }
      }
    }
  };
  isDomainLoaded = new BehaviorSubject<boolean>(false);
  poweredBy = 'Ketto';
  medicalbill = [];

  selected_insurance: {
    vendor: string, // 'bima' | 'medibuddy'
    frequency: string, // 'yearly' | 'monthly'
    plan: string, // 'care' | 'plus' | 'essentials'
    plan_id?: number,
    plan_text: string,
    plan_amount: number,
    currency: string
  } = null;

  criticalIllnessPlans: {};
  selectedIllness: {
    person: number,
    cover_count?: number,
    cover: string,
    illnessAmount: number
  }

  insurance_plans_text = {
    care: 'Care',
    plus: 'Plus',
    essentials: 'Essentials'
  };

  switch_plan = new BehaviorSubject<string>('care');
  reload_qr_code = new BehaviorSubject<boolean>(false);
  tickerData = new BehaviorSubject<any>([]);
  prevOrderDetails: any;
  psw_Pattern_Regex = /^(?=.*[a-z])+(?=.*[A-Z])+(?=.*\d).{8,}$/;
  supported_sip_causes = {
    medical: { text: 'Medical' },
    animals: { text: 'Animals' },
    education: { text: 'Education' },
    food_hunger: { text: 'Food & Hunger' },
    elderly: { text: 'Elderly Care' },
    women_girls: { text: 'Women Empowerment' },
    widow: { text: 'Widow' },
    insurance: { text: 'Healthfirst' },
    sip_protect: { text: 'SIP Protect' }
  };
  default_sip_cause = 'medical';

  sip_status = {
    0: { text: 'New' },
    1: { text: 'Active' },
    2: { text: 'Lapsed' },
    3: { text: 'New' },
    4: { text: 'Cancelled' },
    5: { text: 'Cancel Requested' }
  }

  is_browser: any;
  isIndependanceDay;
  getCartInsurance = new BehaviorSubject<any>(null);
  countSubsCause;

  showHFgift = false;
  minTipRequired = 200;
  giftAmount = '₹500';
  hfGiftCouponCode = '';
  recommendedVpa = '';
  medibuddyPlanName = {
    4716: 'Essentials',
    4717: 'Plus',
    4713: 'Silver'
  }

  subsCountApiCalled = false;

  chfCampaignId = 52033;

  constructor(
    private paymentService: PaymentService
  ) { }


  /** Send last order value */
  setLastOrderValues(order) {
    this.lastOrderMode.next({ mode: order.payment_mode, value: order.payment_mode_value, gateway: order.payment_gateway });
    if (order.checksum) {
      let lastOrder = Object.assign(order, {
        mode_value: order.payment_mode_value,
        mode: order.payment_mode,
        gateway: order.payment_gateway
      });
      if (order.payment_mode === 'UPI') {
        lastOrder.upi_id = order.checksum;
      }
      if (order.payment_mode === 'UPI' && order.payment_gateway === 'camspay') {
        lastOrder = Object.assign(lastOrder, JSON.parse(order.checksum));
      }
      if (order.payment_gateway.match('stripe') && !this.pageName.match('sip_welcome')) {
        lastOrder = Object.assign(lastOrder, JSON.parse(order.checksum));
        this.stripeUsToken = lastOrder.token_1;
      }
      if (order?.donor?.full_name) {
        lastOrder.donor_name = order.donor.full_name.trim();
      }
      this.lastOrderInfo.next(lastOrder);
    }
  }

  /**Listen to the global variable and whenever
   * just need to call it like this
   * globalVarSetter.myKey = "its value";
   */
  listenToGlabalJsVar() {
    if (typeof window !== 'undefined') {
      try {
        (<any>window).globalVarSetter = new Proxy({}, {
          set: (target, key, value) => {
            target[key] = value;
            this.globalVar.next(target);
            return true;
          }
        });
      } catch (e) { }
    }
  }

  async loadTranslationJson() {
    if(typeof window !== 'undefined') {
      const translationJson = await import(`../../../../assets/i18n/stories/${this.tranlatedLanguage}.json`);
      return translationJson.default;
    } else {
      return STORY_LANGUAGE;
    }
  }

  getTranslatedJson(keyString: string) {
    if (keyString) {
      return this.loadTranslationJson().then(translationJson => this.getObjFromStringNotation(translationJson, keyString));
    }
  }
  getTranslatedJsonObs(keyString: string) {
    const subject = new BehaviorSubject<any>('');
    this.languageObs.subscribe(async lang => {
      if (lang) {
        const translationJson = await this.loadTranslationJson();
        subject.next(this.getObjFromStringNotation(translationJson, keyString));
      }
      return lang;
    });
    return subject;
  }

  getObjFromStringNotation(obj, keyString) {
    const keys = keyString.split('.');
    keys.forEach(key => {
      if (key in obj) {
        obj = obj[key];
      } else {
        return;
      }
    });
    return obj;
  }

  getSubsCount(cause?: string) {
    if (!this.subsCountApiCalled || cause) {
      this.subsCountApiCalled = true;
      let query = '';
      if (cause) { query = '?cause=' + cause; }
      this.paymentService.getSubscriptions(query).subscribe((res: any) => {
        this.countSubsCause = query.split('=')[1];
        this.impactData = res.data.data;
        this.subsCount.next(res.data.data);
      });
    }
  }

}
