import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UtilService, VariablesService } from 'src/app/shared/services';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { IMainLayoutConfig } from 'src/app/shared/models/main-layout.config';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-basic-header',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule, MatRippleModule, MatIconModule],
  templateUrl: './basic-header.component.html',
  styleUrls: ['./basic-header.component.scss']
})
export class BasicHeaderComponent {

  @Input() config: IMainLayoutConfig;

  navOptions = [
    { text: 'Home', link: '/' },
    { text: 'About Us', link: '/about-us' },
    { text: 'Contact Us', link: '/contact-us' },
    { text: 'Fundraisers', link: '/all-fundraisers' },
    { text: 'Blog', link: '/blog' }
  ];

  @ViewChild('rightRef') rightRef;
  donateBtnParams = { id: 52033, ct: '5a8bc23a2399a' };

  constructor(
    public util: UtilService,
    public vars: VariablesService,
    private router: Router
  ) {
    this.subs.push(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.vars.isMenuOpen.next(false);
      }
    }));
  }
  ismenuopen = false;
  subs = [];

  ngOnInit() {
    if (this.util.isMobile()) {
      this.handleMobileMenu();
    }
  }

  handleMobileMenu() {
    this.subs.push(this.vars.isMenuOpen.subscribe(val => {
      this.ismenuopen = val;
      if (this.rightRef) {
        this.rightRef.nativeElement.style.display = this.ismenuopen ? 'block' : 'none';
      }
    }));
  }

  toggleMenu() {
    this.ismenuopen = !this.ismenuopen;
    this.vars.isMenuOpen.next(this.ismenuopen);
  }
  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
