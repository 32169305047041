import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ICLientData, IUser } from './shared/models';
import {
  AuthService, CookieService, EventsService, FundraiserService, PortfolioService, ScriptLoaderService, SeoService, StorageService, UtilService, VariablesService
} from './shared/services';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loadingRouteConfig: boolean;
  public result;
  deferredPrompt: any;
  showButton = true;
  params;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public titleService: Title,
    public authService: AuthService,
    public util: UtilService,
    public storageService: StorageService,
    public vars: VariablesService,
    public scriptLoaderService: ScriptLoaderService,
    public seoService: SeoService,
    private events: EventsService,
    private portfolioService: PortfolioService,
    private cookie: CookieService,
    private fundraiserService: FundraiserService
  ) { }

  ngOnInit(): void {
    this.params = this.util.getUrlParams();
    this.setSeo();
    this.moduleLoader();
    this.seoService.createCanonicalURL();
    this.getDomain();
    const webPageSchema = {
      '@context': 'http://schema.org',
      '@id': `${this.util.domain_details?.domain_url}`,
      '@type': 'WebPage',
      'url': `${this.util.domain_details?.domain_url}`,
      'name': `${this.util.domain_details?.domain_name}`
    };
    this.seoService.schemaOrgObject(webPageSchema);
    if (this.util.isBrowser) {
      this.setDeviceType();
      this.routeChangeListner();
      this.getClientIP();
      this.setUser();
    }
    this.setTitle();
    this.vars.listenToGlabalJsVar();
  }

  setDeviceType() {
    this.vars.deviceType = this.util.isMobile() ? 'mobile' : 'desktop';
    const platform = this.cookie.getCookie('platform');
    if (platform) {
      this.vars.deviceType = platform;
    }
    if (this.params?.platform) {
      this.storageService.addSessionData('platform', this.params?.platform);
    }
    const sesPlatform = this.storageService.getFromSession('platform');
    if (sesPlatform) {
      this.vars.deviceType = sesPlatform;
    }
  }

  /**Onlaod function */
  @HostListener('window:load', [])
  onWindowLoads() {
    if (this.util.isBrowser) {
      this.scriptLoaderService.loadScript('gtm', '', true, this.util?.domain_details?.domain_name).then();
      this.scriptLoaderService.loadScript('microsoft_clarity', '', true);
      this.vars.userData.subscribe(res => {
        if (res?.id) {
          this.events.clarityEventsPush(res?.id);
        }
      });
    }
  }

  routeChangeListner() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe((event) => {
      if (this.router.url.split('?')[0] !== this.vars.previousPageURL.split('?')[0]) {
        this.vars.previousPageURL = this.router.url;
        this.vars.pageName = event['page_name'] || '';
        this.gtmOnLoad(event);
        if (!event.lastOrderSkip) {
          this.checkForLastOrder();
        }
      }
    });
  }

  /**Set user logged in flags */
  setUser() {
    try {
      const user = this.storageService.getProperty('user', 'user');
      if (user) {
        if (user.isLoggedIn) {
          this.util.isLoggedIn.next(user.isLoggedIn);
        }
        this.vars.userData.next(this.storageService.get('userdata'));
      }
    } catch (error) {
      console.log(error);
    }
  }
  /**Lazy loader module loader */
  moduleLoader() {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    });
  }
  /**
   * Set page title on the basis of which
   * route is currently active
   */
  setTitle() {
    this.util.setPageTitle(this.vars.domainName);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.util.url.next(event);
      try {
        this.seoService.createCanonicalURL();
      } catch (e) {
        console.log(e);
      }
    });
  }
  /**Get the IP of the client */
  getClientIP() {
    const ip = this.storageService.getFromSession('iplocation');
    if (!ip) {
      this.authService.getClientIP().subscribe((res: any) => {
        this.setClientIP(res.data);
      }, () => this.setClientIP(this.vars.defaultIPLocation));
    } else {
      this.setClientIP(ip);
    }
  }

  setClientIP(ip: ICLientData) {
    this.vars.clientLocationData.next(ip);
    const criteoId = this.vars.criteoPartnerIDs[ip.country_name] || 66025;
    this.events.gtmPush({ 'CriteoPartnerID': criteoId });
    this.storageService.checkFromSession('iplocation', ip);
    this.setCurrency(ip);
  }

  setCurrency(ip: ICLientData) {
    const currencyFromUrl = this.activatedRoute.snapshot.queryParams.selected_currency;
    const currencyFromCode = this.util.getCurrencyFromCode(ip.country_code);
    const currencyFromSession = this.storageService.getFromSession('currency');
    if (currencyFromUrl) {
      this.util.setCurrency(currencyFromUrl);
    } else if (currencyFromSession) {
      this.util.setCurrency(currencyFromSession);
    } else if (currencyFromCode) {
      this.util.setCurrency(currencyFromCode.currency);
    }
  }

  gtmOnLoad(event) {
    const user: IUser = this.storageService.get('userdata');
    const currency = this.storageService.getFromSession('currency');
    const gtmPush = {
      event: 'virtual_pageview',
      page_version: 'A',
      login_status: user ? 'logged in' : 'not logged in', // Populate either LOGGED IN or NOT LOGGED IN
      user_type: user ? user.entity_type : 'visitor',
      currency: currency, // Currency used
      // session_id: '',
      user_id: user ? user.id : '',   // Identifies unique users across multiple devices
      page_category: event.category,  // ex: home, crowdfunding, fundraiser etc
      page_name: event.page_name,  // name of the page
      sip: event.sip || false  // name of the page
    };
    this.vars.gtmPageData = gtmPush;
    this.events.gtmPush(gtmPush);
  }

  /**Get the total orders made by the user */
  checkForLastOrder() {
    this.vars.userData.subscribe(res => {
      if (res && !this.vars.lastOrderInfo.value) {
        this.getLastOrder();
      }
    });
  }
  getLastOrder() {
    const qparam = `?limit=1&orderBy=creation_date&sortedBy=desc`;
    this.portfolioService.getBeneficiaries(qparam).subscribe((res: any) => {
      if (res && res.data && res.data.data && res.data.data.length) {
        this.vars.setLastOrderValues(res.data.data[0]);
      }
    });
  }

  getDomain() {
    this.fundraiserService.getDomain().subscribe((res: any) => {
      this.setSeo({ domainName: res?.data?.name || '', logo: res?.data?.logo, favicon: res?.data?.favicon?.path });

      if (res?.data?.theme?.theme_color && this.util.isBrowser) {
        document.documentElement.style.setProperty('--primary-color', res.data.theme.theme_color);
        const code = this.util.hexToRgb(res.data.theme.theme_color);
        if (code) {
          document.documentElement.style.setProperty('--primary-hover-color', `rgb(${code.r}, ${code.g}, ${code.b}, 1)`);
          document.documentElement.style.setProperty('--primary-color-shadow', `rgb(${code.r}, ${code.g}, ${code.b}, 0.1)`);
          document.documentElement.style.setProperty('--primary-color-shadow-2', `rgb(${code.r}, ${code.g}, ${code.b}, 0.2)`);
          document.documentElement.style.setProperty('--primary-color-shadow-3', `rgb(${code.r}, ${code.g}, ${code.b}, 0.3)`);
          document.documentElement.style.setProperty('--primary-color-rgb', `rgb(${code.r}, ${code.g}, ${code.b})`);
        }
      }
      this.vars.isDomainLoaded.next(true);
    });
  }

  setSeo(data?: { domainName?: string, logo?: { light?: string, dark?: string }, favicon?: string }) {
    if (data?.domainName) {
      this.vars.domainName = data?.domainName;
    }
    this.util.setLogo(data?.logo || { light: this.vars.domainLogo });
    this.util.setFavicon(data?.favicon || this.vars.domainFavicon);
  }

}
