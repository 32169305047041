export const STORY_LANGUAGE = {
  "Fundraiser": {
    "proceedPay": "Proceed to Pay"
  },
  "Stories": {
    "urgentNeedFunds" : "This fundraiser is in an urgent need of funds",
    "urgentNeedFunds1" : "This Patient need funds urgently",
    "storyText": "Story",
    "documentText":"Documents",
    "updatesText": "Updates",
    "proceedDonate": "Donate",
    "faq": "FAQs",
    "readMore": "Read full story",
    "daysLeft": "days left",
    "donors": "Donors",
    "hospital": "Hospital",
    "updates": {
      "feelFreeUpdate1": "Feel free to ask",
      "feelFreeUpdate2": "for a new update on this Fundraiser.",
      "askUpdate": "Ask for update",
      "updateReq": "Update Requested",
      "latestUpdate": "Latest Update",
      "fbShare": "Did you know that every FB share gets 1 more donation. Help spread the word!",
      "spreadtheWord": "Spread the word"
    },
    "donations": {
      "donateVia": "Donate Via",
      "donateViaUPI": "Donate Via UPI",
      "or": "OR",
      "chooseAmtToDonate": "Choose the amount you wish to Donate",
      "claimYourAckNow": "Claim your donation acknowledgement now!",
      "provideWithDetails": "Provide us with your details",
      "beneficiary": "Beneficiary",
      "contact": "Contact",
      "contactDesc1" : "Your message will be directly emailed to beneficiary",
      "contactDesc2" : " and you will receive his/her response on the email provided by you",
      "enterMessage" : "Enter a message",
      "send": "Send",
      "verifiedByKetto": "Verified by Ketto’s Team at the Hospital",
      "viewNoSupporter1": "View all",
      "viewNoSupporter2": "supporters",
      "makeDonation": "Make A Donation",
      "donatingIn": "You are donating in",
      "back": "Back",
      "name": {
        "placeholder": "Name",
        "error": {
          "minlength": "Please enter a valid name",
          "maxlength": "Maximum character limit exceeds",
          "pattern": "Please enter a valid name"
        }
      },
      "donationAnonymous": "Make my donation anonymous",
      "email": {
        "placeholder": "Email ID",
        "error": {
          "email": "Please enter a valid email",
          "pattern": "Please enter a valid email"
        }
      },
      "phone": "Your Mobile number",
      "enterValidPhone": "Please enter a valid number",
      "termsconditionsStatement1": "By continuing, you agree to our",
      "termsService": "Terms of Service",
      "privacyPolicy": "Privacy Policy",
      "and": "and",
      "allpayment": "All Payment updates will be sent on this number.",
      "otheramount": "Other Amount",
      "termsconditionsStatement2": "",
      "sealTrustAuthenticity": "Seal of Trust & Authenticity",
      "trustByKetto": "Trusted online crowdfunding platform run by Ketto",
      "directToBeneficiary": "Funds raised go directly to the beneficiary verified by Ketto",
      "donationProtection": "100% donation protection - Receive easy refunds & timely updates",
      "donorProtection": "100% donor protection - No threat to security and privacy",
      "encrypted": "All payments are secured via 128-bit SSL encryption",
      "couldUseHelp": "I could really use your help right now.",
      "smallContribution": "You can help me with a contribution as small as",
      "noThanks": "No, Thanks",
      "surewillHelp": "Sure I will help",
      "medDocs": "Medical Documents",
      "caseAssigned": "Case assigned to"
    },
    "contact": {
      "contact": "Contact",
      "assignee": "Case assigned to",
      "hospital": "Hospital",
      "further": "For further assistance, contact a Ketto representative",
      "contacthospital": "Contact Hospital"
    },
    "bankTransfer": {
      "donationMethods": "Other donation methods",
      "transferToDescription": "Transfer directly to the Bank account of this Fundraiser. Only INR transfers are allowed.",
      "virtualAccNo": "Virtual A/C No ",
      "virtualAccName": "Virtual A/C Name ",
      "accountType": "A/C Type ",
      "current": "Current",
      "ifsc": "IFSC",
      "bankTransfer": "Bank Transfer",
      "paymentOption": "(For payment done via PayTM, UPI & Bank Transfers)",
      "paymentModeTitle": "Select Payment Mode",
      "bankTransferId": "Bank Transaction ID",
      "upiRefOrTxnId": "UPI Ref/Txn ID (received in SMS)",
      "walletOrderId": "Wallet Order ID",
      "requiredField": "Field Required",
      "valueGreaterValidation": "Please enter a value greater than or equal to",
      "ag": "AG",
      "amtDonated": "Amount Donated",
      "anonymousModeTitle": "Make my donation anonymous",
      "submit": "Submit",
      "txnNotFound": "No Transaction Found",
      "dismiss": "Dismiss"
    },
    "upiPayment": {
      "upiOptionTitle": "You can also pay via Paytm/UPI QR code",
      "qrCodeTitle": "Scan the QR code from the app and make payment.",
      "forPatym": "For Paytm",
      "paytmWalletPayScanTitle": "Scan here to pay with Paytm Wallet",
      "genQr": "Generate QR",
      "forOtherApps": "For Other Apps",
      "donationClaimAck1": "Claim your donation acknowledgement now! For payment done via",
      "donationClaimAck2":"",
      "paytm": "Paytm",
      "upi": "UPI"

    },
    "referAFriend": {
      "referAFriend": "REFER A FRIEND",
      "referAFriendBtn": "REFER A FRIEND",
      "contactDesc1": "In need of funds for medical treatment or know someone who might be? Share the details and Ketto will get in touch with.",
      "contactDesc2": "In need of funds for medical treatment or know someone who might be?",
      "question1": "What will the funds be used for?",
      "option1": "Cancer treatment",
      "option2": "Accident treatment",
      "option3": "Liver/Kidney transplant",
      "option4": "Heart operation",
      "option5": "Premature baby care (NICU)",
      "option6": "Medicines (Ongoing treatment)",
      "option7": "Memorial (Patient expired)",
      "option8": "Others",
      "hospitalisationStatus": "Hospitalisation status",
      "hospitalStatusOption1": "Currently hospitalised",
      "hospitalStatusOption2": "Will be hospitalised within 6 months",
      "hospitalStatusOption3": "Discharge from hospital",
      "hospitalStatusOption4": "Does not require hospitalisation",
      "thankYouForSharingDetails": "Thank you for sharing your details!",
      "willGetBackToYouSoon": "We will get back to you soon!",
      "reachToUs": "You can also reach out to us at +91 9930088522",
      "enterValidName": "Please enter a valid name",
      "supportersComments": "Supporters’ Comments",
      "supportersCommentsv3": "Comments",
      "writeSomethingToCheer": "Add a comment...",
      "POST": "POST",
      "showMore": "Show More",
      "supportersCanViewSupporter": "Only supporters of this fundraiser can view more supporters.",
      "supporters": "Supporters",
      "beneficiary": "Beneficiary",
      "contact": "Contact",
      "verifiedKettoHosp": "Verified by Ketto’s Team at the Hospital",
      "verificationDescription": "We, at Ketto, are extremely particular about the authenticity of a campaign. In order to ensure it is genuine, we follow a strict 3-step process before publishing the story"
    },
    "expenses": "Expenses",
    "typeexpense": "Type of Expense",
    "amount": "Amount",
    "eminent": "Eminent Donors on Ketto",
    "about": {
      "aboutKetto": "About Ketto",
      "desc": "DomainName helps people help each other. We are a part of Ketto - an online fundraising platform that’s committed to connecting those who need help with those who want to give it. We support underprivileged patients, especially children, who can't afford life-saving medical treatments.",
      "review1":"Our team follows a rigorous and stringent procedure to check the genuineness of every campaign, ensuring a trustworthy crowdfunding experience.",
      "review2":"Payment process and donors’ data is completely secured and encrypted"
    },
    "follow": "283,763 people follow Ketto",
    "like": "264,890 people like Ketto",
    "termsofuse": "Terms of Use",
    "useofcookies": "Use of cookies",
    "trustandsafety": "Trust & Safety",
    "allrights": "All Rights Reserve"
  }
}
