import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/components/main-layout/main-layout.component';
import { PaymentRoutingComponent } from './shared/components/payment-routing/payment-routing.component';

const routes: Routes = [
  // handle old url
  {
    path: 'fundraisers/all_fundraisers.php',
    redirectTo: 'all-fundraisers'
  },
  {
    path: 'pages/about-us',
    redirectTo: 'about-us'
  },
  {
    path: 'pages/contact-us',
    redirectTo: 'contact-us'
  },
  {
    path: 'pages/blog',
    redirectTo: 'blog'
  },
  {
    path: 'new/payment-route',
    component: PaymentRoutingComponent,
    data: {
      page_name: 'payment-redirect-page',
      category: 'payment',
      lastOrderSkip: true
    }
  },
  {
    path: '', component: MainLayoutComponent,
    children: [
      {
        path: 'stories',
        loadChildren: () => import('./story/story.module').then(m => m.StoryModule)
      },
      {
        path: 'new/thankyou',
        loadChildren: () => import('./thankyou-page/thankyou-page.module').then(m => m.ThankyouPageModule)
      },
      {
        path: 'trustandsafety',
        loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)
      },
      {
        path: 'privacypolicy',
        loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)
      },
      {
        path: 'contact-us',
        loadComponent: () => import('./pages/contact-us/contact-us.component').then(c => c.ContactUsComponent),
        data: {
          page_name: 'contact-us'
        }
      },
      {
        path: 'all-fundraisers',
        loadComponent: () => import('./pages/all-fundraisers/all-fundraisers.component').then(c => c.AllFundraisersComponent),
        data: {
          page_name: 'all-fundraisers'
        }
      },
      {
        path: 'new/payment',
        loadChildren: () => import('./common/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: '404',
        loadChildren: () => import('./page-not-found/page-not-found.route').then(r => r.PageNotFound)
      },
      {
        path: '',
        loadComponent: () => import('./pages/home/home.component').then(c => c.HomeComponent),
        data: {
          page_name: 'home'
        }
      },
      {
        path: 'about-us',
        loadComponent: () => import('./pages/about/about.component').then(c => c.AboutComponent),
        data: {
          page_name: 'about'
        }
      },
      {
        path: 'blog',
        loadComponent: () => import('./pages/blog/blog.component').then(c => c.BlogComponent),
        data: {
          page_name: 'about'
        }
      },
      // {
      //   path: '',
      //   redirectTo: '404'
      // }
    ]
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
