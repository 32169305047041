import { StorageService } from './../storage/storage.service';
import { VariablesService } from './../variables/variables.service';
import { CookieService } from '../cookie/cookie.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ICampaign } from '../../models/campaign.model';
import { ICurrency } from '../../models/currency.model';
import { Currency, EuropeanCountries, GulfCountries } from '../../models/currency-list';
import { IHost } from '../../models/host.model';
import { ScriptLoaderService } from '../script-loader/script-loader.service';

declare let blitz;
/**
 * Utility service which provides commonly used
 * functions/medthods
 */
@Injectable({
  providedIn: 'root'
})
export class UtilService {

  imageDomain = environment.APP.IMAGE_DOMAIN;
  defaultImg = '/assets/images/default-img.svg';
  defaultBlurImg = '/assets/images/defaultBlurImg.png';
  logoUrlLight = '';
  logoUrlDark = '';
  placeholderGif = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  /**Campaign Type  */
  public campaignType = new BehaviorSubject<any>('');
  public campaignType$ = this.campaignType.asObservable();
  /**International Tel Input variable*/
  public number = new Subject<any>();
  public number$ = this.number.asObservable();
  /**Get params from campaign*/
  public param = new Subject<any>();
  public param$ = this.param.asObservable();
  /**Get params from campaign*/
  public url = new BehaviorSubject<any>(null);
  public url$ = this.url.asObservable();
  /**Get params from campaign*/
  public donorList = new BehaviorSubject<any>(null);
  public donorList$ = this.donorList.asObservable();
  /**Get currency*/
  public currency = new BehaviorSubject<any>(null);
  /**Site Url*/
  public siteUrl = new Subject<any>();
  public siteUrl$ = this.siteUrl.asObservable();
  /**Is Logged In*/
  public isLoggedIn = new BehaviorSubject<any>(false);
  public isLoggedIn$ = this.isLoggedIn.asObservable();
  /**Domain Details */
  public domain_details: IHost;
  /**loading from browser or server*/
  public isBrowser: boolean;
  /**Currecncies */
  public currencies: ICurrency[] = Currency;
  public europeanCountries = EuropeanCountries;
  public gulfCountries = GulfCountries;
  /**Sent token to specific req*/
  public isToken = true;
  /**Intl tel input error observable */
  public checkIntlTelError = new BehaviorSubject<any>(false);

  private bodyScrollTop;
  doc;

  utm_url_string: string = '';
  utm_url_obj: any;

  constructor(
    public router: Router,
    public actRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public titleService: Title,
    private vars: VariablesService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) public document: any,
    private zone: NgZone,
    private cookieService: CookieService,
    private storageService: StorageService,
    private scriptLoaderService: ScriptLoaderService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (typeof window !== 'undefined') {
      this.domain_details = {
        domain_name: window.location.hostname,
        full_url: window.location.href,
        domain_url: window.location.origin,
      };
      this.doc = document;
      this.utm_url_string = this.getUTMonly('url_string');
      this.utm_url_obj = this.getUTMonly();
    }
  }

  setDomainDetails() {
    let domain = 'default';
    if(Object.keys(this.vars.allDomains).includes(this.domain_details.domain_name)) {
      domain = this.domain_details.domain_name;
    }
    this.vars.domainName = this.vars.allDomains[domain].name;
    this.vars.domainLogo = this.vars.allDomains[domain].logo;
    this.vars.domainFavicon = this.vars.allDomains[domain].favicon;
  }

  setUser(user) {
    if (this.vars.isVariableLogin) {
      this.vars.varLoginData.user = user;
    } else {
      this.storageService.check('user', user);
    }
    return true;
  }

  getUserData() {
    if (this.vars.isVariableLogin) {
      return this.vars.varLoginData.userdata;
    } else {
      return this.storageService.get('userdata');
    }
  }

  getUser() {
    if (this.vars.isVariableLogin) {
      return this.vars.varLoginData.user;
    } else {
      return this.storageService.get('user');
    }
  }

  setLogo(logo: { light?: string, dark?: string }) {
    this.logoUrlLight = logo?.light || '';
    this.logoUrlDark = logo?.dark || '';
  }

  setFavicon(url) {
    if (url) {
      let link = this.document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = `${url}`;
      this.document.getElementsByTagName('head')[0].appendChild(link);
    }
  }

  blitzEventHandler(eventName: any) {
    if (this.isBrowser) {
      this.scriptLoaderService.loadScript('blitzlamaScript', '', true);
      const interval = setInterval(() => {
        if (window['blitz']) {
          blitz('triggerEvent', eventName);
          clearInterval(interval);
        }
      }, 1000);
    }
  }
  /**
   * Get the icon of the currency
   */
  getCurrencyIcon(currency): ICurrency {
    for (const item of this.currencies) {
      if (currency === item.currency) {
        return {
          code: item.code,
          is_symbol: item.is_symbol,
          icon: item.icon,
          currency: item.currency,
          symbol: item.symbol
        };
      }
    }
  }
  /**Native Navigation
   * @param url Complete url
   */
  nativeNavigation(url: string, in_new_tab?) {
    if (typeof window !== 'undefined') {
      if (this.utm_url_string && !url?.match('utm_')) {
        url += (url?.includes('?') ? '&' : '?') + this.utm_url_string;
      }
      if (in_new_tab) {
        window.open(url, '_blank');
      } else {
        document.location.href = url;
      }
    }
  }
  /**
   * Navigation medthod
   * @param path
   */
  navigate(path: string, param?: string) {
    param ?
      this.router.navigate([path, param], { queryParamsHandling: 'merge' }) :
      this.router.navigate([path], { queryParamsHandling: 'merge' });
  }
  /**Check is on mircro site */
  isMicroSite() {
    const name: string = this.domain_details?.domain_name;
    if (name.match(/www.ketto.org|dev\d.ketto.org|localhost/gm)) {
      return false;
    } else {
      return name.match(/ketto.org/gm) ? true : false;
    }
  }
  /**Check is on White labeled site */
  isWLSite() {
    return this.domain_details?.domain_name?.match(/ketto.org|localhost/gm) ? false : true;
  }
  /**Get the parm query */
  getParamQuery(id) {
    return this.actRoute.snapshot.paramMap.get(id);
  }
  /**Get the origin of the page */
  getOrigin(name) {
    switch (name) {
      case 'campaign':
        return 'f';
      case 'stories':
        return 's';
      default:
        return 'f';
    }
  }
  /**File upload validation */
  onFileChange(event) {
    const regEx = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const fileSize = file.size / 1024 / 1024;
      if (regEx.exec(file.name)) {
        if (fileSize < 10) {
          return { message: 'Success', error: false };
        } else {
          return { message: 'File size exeeds 10MB', error: true };
        }
      } else {
        return { message: 'Unsupported file format', error: true };
      }
    }
  }
  /**Check controls validation on submit */
  checkValidForm(form) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  /**Format date according to the db requirement */
  formatDate(date: Date) {
    if (date) {
      const temp = date.toISOString().split('T');
      const d = temp[0];
      const t = temp[1].split('.')[0];
      return d.concat(' ' + t);
    }
  }
  /**
   * Snack bar for error notifications
   */
  openSnackBar(message, type, config?: MatSnackBarConfig) {
    this.zone.run(() => {
      const snackBarClass = type === 'success' ? 'snackbar-success' : 'snackbar-error';
      const matConfig: MatSnackBarConfig = {
        verticalPosition: config && config.verticalPosition ? config.verticalPosition : 'top',
        horizontalPosition: config && config.horizontalPosition ? config.horizontalPosition : 'center',
        panelClass: [snackBarClass],
        duration: config && config.duration ? config.duration : 100000
      };
      this.snackBar.open(message, 'DISMISS', matConfig).afterOpened().subscribe(() => {
        if (typeof document !== 'undefined') {
          const el = document.getElementsByTagName('snack-bar-container').item(0);
          el.parentElement.parentElement.style.zIndex = '1001';
        }
      });
    });
  }
  /**Start the similar fundraiser */
  startSimilar(fundraiser, type?) {
    let campaignType = type;
    if (!type) {
      campaignType = this.getCampaignTypeFromId(fundraiser.parent_cause_id);
    }
    const url = this.domain_details?.domain_url + '/campaign/start_new_campaign.php?type=' + campaignType;
    this.nativeNavigation(url);
  }
  /**Set array according to the
   * requirement of select componnent
   * @param array - response from api
   **/
  setArray(array, id: string, label: string) {
    const temp: any[] = [];
    for (const item of array) {
      temp.push({
        'value': item[id],
        'label': item[label],
      });
    }
    return temp;
  }
  /**
   * Patch or fill the values in form
   * @param formgroup The form name
   * @param data The data to be filled in form
   */
  patchForm(formgroup: FormGroup, data: ICampaign) {
    //
  }
  /**
   * Add response in object
   * @param newOb object you want to add
   * @param oldOb in this new object values will be added
   */
  addObject(newOb, oldOb) {
    for (const key of Object.keys(newOb)) {
      oldOb[key] = newOb[key];
    }
    return oldOb;
  }

  /**Sort array */
  sortArray(array) {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
  sortArrayOfBject(array, key) {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  /**
   * Set the complete path of the image
   * comes from server
   * @param dataArray Data object of Campign draft
   * @param mediaType Media type of the campaign
   */
  setImagePath(dataArray: ICampaign, mediaType: string) {
    for (const item of dataArray.media) {
      if (item.display_type_1 === mediaType) {
        return environment.APP.IMAGE_DOMAIN + item.path + item.file_name;
      }
    }
  }
  /**
   * Get Initials of name
   * But only two initials
   * @param name Name
   */
  getInitialsOfName(name: string) {
    if (name) {
      const data = name ? name.split(' ') : [];
      if (data.length > 1) {
        return data[0].charAt(0) + data[1].charAt(0);
      } else {
        return data[0].charAt(0).trim();
      }
    }
  }
  /**
   * Capitalize the every first latter of
   * given string
   * @param name Given String
   */
  capitalizeFirstLatter(name: string) {
    if (name) {
      const data = name.trim().split(' ');
      for (const index of Object.keys(data)) {
        data[index] = data[index].charAt(0).toUpperCase() + data[index].substring(1);
      }
      return data.join(' ');
    } else {
      return name;
    }
  }
  /**
   * Check fundraiser status is completed or not
   * @param status Status number of the fundraiser
   * @param endDate End date of the fundraiser
   */
  checkFundraiserStatus(status, endDate) {
    if (endDate) {
      const arr = endDate.split(/[- :]/);
      const dt = new Date(Date.UTC(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));
      if (dt.getTime() - new Date().getTime() < 0) {
        return true;
      }
    }
    switch (status) {
      // Fundraiser Active
      case 1:
        return false;
      // Fundraiser Expired
      case 4:
        return true;
      // Fundraiser Active
      case 5:
        return false;
    }
  }
  /**Check if fundraiser made the
   * succesfull campaign
   * @param amount Set amount of the fundraiser
   * @param raised How raised for the fundraiser
   */
  checkIfSuccessfullyFunded(amount, raised) {
    if (amount && raised) {
      return raised / amount > 0.6 ? true : false;
    } else {
      return false;
    }
  }
  /**Set title of the page */
  setPageTitle(name) {
    const title = this.capitalizeFirstLatter(name);
    this.titleService.setTitle(`${title}`);
  }
  /**Get youtuve idd from its url */
  getYouTubeId(url) {
    const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const match = url.match(regEx);
    if (match && match[1] && match[1].length === 11) {
      return match[1];
    }
  }
  /**Detect the browser */
  detectBrowser(): String {
    if (typeof window !== 'undefined') {
      const userAgent = window.navigator.userAgent;
      const browser = userAgent.match('Chrome|Firefox|Safari|NetScape|Opera|MSIE');
      return browser ? browser[0] : '';
    } else {
      return '';
    }
  }
  /**
  * Get device type
  */
  isMobile() {
    if (this.isBrowser) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
    }
    return false;
  }
  /**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
  getMobileOperatingSystem() {
    if (this.isBrowser) {
      const userAgent = navigator.userAgent || navigator.vendor;
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
      }
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'iOS';
      }
      return 'unknown';
    }
  }
  /**
   * Get the type of the user visting the campaign page
   */
  getUserType(campainerId, enityId, userId) {
    switch (true) {
      case campainerId === userId:
        return 'Campaigner';
      case enityId === userId:
        return 'NGO';
      default:
        return 'Visitor';
    }
  }
  /**get campaign type from id */
  getCampaignTypeFromId(id) {
    switch (id) {
      case 20:
        return 'personal';
      case 48:
        return 'ngo';
      case 49:
        return 'creative';
      case 149:
        return 'ad';
    }
  }
  /**Returns URL params in object form */
  getUrlParams() {
    if (typeof document !== 'undefined') {
      const search = document.location.search.substring(1);
      return search.split('&').reduce(function (prev, curr) {
        if (curr) {
          const p = curr.split('=');
          prev[p[0]] = decodeURIComponent(p?.[1]) || '';
        }
        return prev;
      }, {});
    }
  }
  navigateToQueryParam(key: string, value: string) {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    this.router.navigate([], {
      relativeTo: this.actRoute,
      queryParams: {
        [key]: value
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      // skipLocationChange: true
      // do not trigger navigation
    });
  }

  zenDeskLoadedCB() {
    if (isPlatformBrowser(this.platformId) && this.isMobile()) {

      (<any>window).zE('webWidget:on', 'open', () => {
        this.setBodyUnscrollable(true);
      });

      (<any>window).zE('webWidget:on', 'close', () => {
        this.setBodyUnscrollable(false);
      });

    }
  }

  setBodyUnscrollable(value) {
    if (value && typeof window !== 'undefined') {
      this.bodyScrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
      document.body.style.overflow = (value) ? 'hidden' : '';
      document.body.style.position = (value) ? 'fixed' : '';
      document.body.style.left = (value) ? '0' : '';
      document.body.style.right = (value) ? '0' : '';
      document.body.style.top = (value) ? - this.bodyScrollTop + 'px' : '';
      if (!value) {
        document.documentElement.scrollTop = this.bodyScrollTop;
        document.body.scrollTop = this.bodyScrollTop;
      }
    }
  }

  /**Get from cookiee */
  getCookie(name) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
  /**Get document referrer */
  getReferrer() {
    if (this.isBrowser) {
      return document.referrer;
    } else {
      return '';
    }
  }
  moveMedicalCauseToTop(personalCauses) {
    // Move medical cause to top of list
    // find the current index of 'Medical' cause;
    const medicalIndex = personalCauses.findIndex(cause => cause.info_1 === 'Medical' || cause.label === 'Medical');
    const medicalCause = personalCauses[medicalIndex];
    if (medicalIndex > 0) {
      // using splice to remove elements from the array, starting at
      // the identified index, and affecting 1 element(s):
      personalCauses.splice(medicalIndex, 1);
      // putting the 'Medical' object back in the array:
      personalCauses.unshift(medicalCause);
    }
    return personalCauses;
  }

  /**Scroll page to the top */
  scrollToTop(behavior?) {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: behavior || 'smooth' });
    }
  }

  getPhoneWithExt(data) {
    let phone = '';
    if (data.extension) {
      phone = '+' + data.extension;
    }
    if (data.phone_1) {
      phone += data.phone_1;
    }
    return phone;
  }

  getDateInIos(date) {
    if (!date) { return new Date(); }
    const arr = date.split(/[- :]/);
    const dt = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return dt;
  }

  /**Get utm parameters */
  getUTMs() {
    const utmsInUrl = this.getUrlParams();
    const utmsInCookie = this.cookieService.getDecodedCookies('k_utm');
    if (utmsInUrl && utmsInUrl.hasOwnProperty('utm_source')) {
      return utmsInUrl;
    } else if (utmsInCookie && utmsInCookie.hasOwnProperty('utm_source')) {
      return utmsInCookie;
    } else {
      return {};
    }
  }

  getUTMonly(returnType?: 'url_string' | ''): any {
    const parmas = this.getUTMs();
    // tslint:disable-next-line: max-line-length
    const utmParams = Object.keys(parmas).filter(key => key.toString().toLocaleLowerCase().match('utm')).reduce((cur, key) => Object.assign(cur, { [key]: parmas[key] }), {});
    if (returnType === 'url_string') {
      return Object.keys(utmParams).length ? this.getUrlQueryStringFromObject(utmParams) : '';
    } else {
      return utmParams;
    }
  }

  /** Get city from ip */
  getCityFromIp() {
    const iplocation = this.storageService.getFromSession('iplocation');
    if (iplocation && iplocation.city_name) {
      return iplocation.city_name;
    }
    return '';
  }

  /** Scroll into view smoothly */
  scrollIntoViewSmoothly(id, top?) {
    if (typeof window !== 'undefined') {
      const elRef = document.getElementById(id);
      if (elRef) {
        window.scrollTo({
          top: elRef.offsetTop - (top || 20),
          behavior: 'smooth'
        });
      }
    }
  }

  /** Scroll into view smoothly by class*/
  scrollIntoViewSmoothlyByClass(class_name, el_pos = 0, top = 20, behavior?) {
    if (typeof window !== 'undefined') {
      const el = document.getElementsByClassName(class_name);
      if (el.length) {
        if (!el[el_pos].id) { el[el_pos].id = 'scroll-id'; }
        window.scrollTo({
          top: document.getElementById(el[el_pos].id).offsetTop - top,
          behavior: behavior || 'smooth'
        });
      }
    }
  }

  setBasicInfo(basicInfo, beneficary) {
    const data = { basicInfo: {}, beneficiary: {} } as any;
    if (basicInfo && basicInfo.length) {
      for (const item of basicInfo) {
        data.basicInfo[item.info_type] = item.info_1 || '';
      }
      if (beneficary) {
        data.beneficiary = beneficary;
      } else if (data.basicInfo.beneficiary_name) {
        data.beneficiary['full_name'] = data.basicInfo.beneficiary_name;
        data.beneficiary['relation'] = data.basicInfo.beneficiary_relation;
      } else {
        data.beneficiary = undefined;
      }
      return data;
    }
  }

  addCssToGlobal(link) {
    if (typeof document !== 'undefined') {
      const _el = document.createElement('link');
      _el.href = link;
      _el.rel = 'stylesheet';
      document.getElementsByTagName('head')[0].appendChild(_el);
    }
  }

  formatAmount(value, currency) {
    if (this.isBrowser && value && currency) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    }
  }

  /** Convert the color hex code to rgba format
   *  @param hex: Hex code of any color
   */
  hexToRgb(hex): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  /**
   * Gets prize amount
   * @param currency currency
   * @returns prize amount
   */
  public getPrizeAmount(currency: string): number {
    switch (currency) {
      case 'INR': case 'RS': return 300;
      case 'USD': case 'AED': return 60;
      case 'EUR': case 'GBP': case 'SAR': case 'SGD': return 30;
    }
  }


  /**
  * get filtered object by value
  */
  filterObject(object, filterString) {
    const filteredObj = {};
    for (const key in object) {
      if (object[key].toLowerCase().indexOf(filterString.toLowerCase()) > -1) {
        filteredObj[key] = object[key];
      }
    }
    return filteredObj;
  }

  checkNestedObj(obj, key) {
    return key.split('.').reduce((o, x) => (typeof o === 'undefined' || o === null) ? o : o[x], obj);
  }

  isIOSDevice() {
    if (this.isBrowser) {
      return /iPhone Simulator|iPad Simulator|iPod Simulator|iPad|iPhone|iPod/.test(navigator.userAgent);
    }
  }

  isAppleDevice() {
    if (this.isBrowser) {
      return /iPhone Simulator|iPad Simulator|iPod Simulator|iPad|iPhone|iPod|Mac/.test(navigator.userAgent);
    }
  }

  /**
  * get calculated sip amount based on total for specific months
  */
  calcSipAmountPerMonth(amount, months = 12) {
    const sNumber = (amount / months).toFixed().toString();
    let output;
    let out;
    for (let i = 0, len = sNumber.length; i < len; i++) {
      if (i < sNumber.length - 2) {
        output = `${output || ''}${sNumber.charAt(i)}`;
      } else {
        out = `${out || ''}${sNumber.charAt(i)}`;
      }
    }
    if (+out >= 25 && +out < 75) {
      output = `${output || ''}50`;
    } else if (+out >= 75 && +out <= 99) {
      output = `${+output + 1}00`;
    } else if (+out >= 0 && +out < 25) {
      output = `${output}00`;
    }
    return +output;
  }

  /** Get currency from ip location */
  getCurrencyFromCode(code) {
    const currency = this.currencies.find(item => item.code === code);
    if (currency) {
      return currency;
    }
    if (this.europeanCountries.includes(code)) {
      return this.currencies.find(item => item.code === 'EUR');
    } else if (this.gulfCountries.includes(code)) {
      return this.currencies.find(item => item.code === 'SAR');
    } else {
      return this.currencies.find(item => item.code === 'US');
    }
  }

  getUrlQueryStringFromObject(qParamObject) {
    if (typeof document !== undefined) {
      return new URLSearchParams(qParamObject).toString();
    }
  }

  getCurrencyObj(currency) {
    return this.currencies.find((item) => item.currency === currency);
  }

  setCurrency(currency) {
    this.currency.next(currency);
    this.storageService.addSessionData('currency', currency);
  }

  // remove empty value attributes from object
  removeEmptyFromObject(obj) {
    // tslint:disable-next-line: max-line-length
    return Object.entries(obj).reduce((o, [key, value]) => (value === '' || value === null || value === undefined || value === 'undefined' ? o : (o[key] = value, o)), {});
  }

  redirectWithUTM(redirectTo, paramObj?: object) {
    const qParams = this.actRoute.snapshot.queryParams;
    const utmObj = {
      ...qParams, ...paramObj
    };
    if (!utmObj?.utm_source) {
      utmObj.utm_source = 'internal_Ketto';
    }
    if (qParams?.utm_content) {
      utmObj.utm_content = qParams?.utm_content;
    }
    this.router.navigate([redirectTo], { queryParams: utmObj });
  }

  checkForActiveSip(subs) {
    if (!subs) {
      return 0;
    }
    return Object.keys(subs).map(key => !key.match('memorial') ? subs[key].status_flag : null).sort()[0] || 0;
  }

  toTitleCase(data) {
    if (typeof data === 'string') {
      return data.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
    } else if (typeof data === 'object') {
      let new_str = '';
      for (const key in data) {
        new_str = new_str + (new_str ? '-' : '') + data[key].toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
      }
      return new_str;
    }
    return data;
  }

  numberToComaString(num) {
    const n = num;
    if (typeof num === 'number') {
      num = parseInt(n, 10).toLocaleString('en-IN').split('.')[0];
    }
    return num;
  }

  arrayMovePosition(arr, key, value, new_index) {
    const old_index = arr.findIndex(function (a) { return a[key] == value; });
    if (old_index >= 0) {
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    }
    return arr;
  }

  isApp() {
    return this.cookieService.getCookie('platform');
  }

  objValuesToLowerCase(data: any) {
    if (typeof data === 'object') {
      if (Array.isArray(data)) {
        data = data.map(obj => obj = this.objValuesToLowerCase(obj));
      } else {
        Object.keys(data).map(key => {
          if (typeof data[key] === 'string') {
            data[key] = this.objValuesToLowerCase(data?.[key]);
          }
        });
      }
    } else if (typeof data === 'string') {
      data = data?.toLowerCase();
    }
    return data;
  }

  objToUrlString(data, param_key?) {
    if (typeof data === 'object') {
      let new_str = '';
      for (const key in data) {
        if (typeof data[key] === 'object') {
          new_str = new_str + (new_str ? '&' : '') + this.objToUrlString(data[key], key);
        } else {
          new_str = new_str + (new_str ? '&' : '') + (param_key || key) + '=' + data[key];
        }
      }
      return new_str;
    }
    return data;
  }

  getLengthToMultiply(data: any) {
    if (typeof data === 'object') {
      return data?.length || 1;
    } else {
      return 1;
    }
  }

  arrayTostring(data: any, seperator?) {
    let str = '';
    if (Array.isArray(data)) {
      data.forEach((v, i) => { str = str + (str && i < data.length ? seperator || '' : '') + v });
    } else if (typeof data === 'string') {
      str = data;
    }
    return str;
  }

  arrayOfObjTostring(data: any, separator: string = '', join_with: string = ':') {
    if (Array.isArray(data)) {
      return data.map(obj => this.arrayOfObjTostring(obj, separator)).join(join_with);
    } else if (typeof data === 'object') {
      const values = Object.values(data).map(value => {
        if (typeof data === 'object') {
          return this.arrayOfObjTostring(value, separator);
        }
        return value;
      });
      return values.join(separator);
    } else {
      return String(data);
    }
  }

  jsonToString(data: any) {
    if (typeof data === 'object') {
      try {
        data = JSON.stringify(data);
      } catch (error) { }
    }
    return data;
  }

  stringToJson(str: any) {
    if (typeof str === 'string') {
      try {
        return JSON.parse(str);
      } catch (error) { }
    }
    return str;
  }

  getCurrentDomain() {
    if (typeof window !== 'undefined') {
      if(Object.keys(this.vars.allDomains).includes(this.domain_details.domain_name)) {
        return this.vars.allDomains[this.domain_details.domain_name];
      } else {
        return this.vars.allDomains.default;
      }
    }
  }

   /**navigate with type */
  navigateToMedicalForm(param: any, type: any, id: any) {
    this.router.navigate(
      [this.vars.startAFrUrls['medical'], param],
      { queryParams: { did: id }, queryParamsHandling: 'merge' }
    );
  }

  setUserData(userdata) {
    if (this.vars.isVariableLogin) {
      this.vars.varLoginData.userdata = userdata;
    } else {
      this.storageService.check('userdata', userdata);
    }
    return true;
  }

  /**navigate with type */
  navigateToForm(param: any, type: any, id: any) {
    this.router.navigate(
      [this.vars.startAFrUrls['non-medical'], param],
      { queryParams: { did: id, type }, queryParamsHandling: 'merge' }
    );
  }

  activateVWOTest(customUrl: String) {
    if (this.isBrowser) {
      window['VWO'] = window['VWO'] || [];
      window['VWO'].push(['activate', {
        customUrl
      }]);
    }
  }

  redirectToLogin(urlParam?) {
    const url = window.location.href.split('?')[0]
    const params = window.location.href.split('?')[1]
    this.nativeNavigation(`${environment.APP.KETTO_DOMAIN_URL}/new/signin?redirect_uri=${urlParam || url}&${params}`);
  }
}
